import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Typography,
  Button,
  Row,
  Col,
  Spin,
  message,
  Checkbox,
  Input,
  Select,
  DatePicker,
  InputNumber,
} from "antd";
import { CopyOutlined, ExportOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { SAMPLE_FORM_SCHEMA } from "./sample";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import "./AggregationGigWorker.css";

const { Title, Paragraph } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const validateUrl = (url) => {
  const regex = /^https:\/\/(www\.)?x\.com\/.+/;
  return regex.test(url);
};

// interface PayloadShape {
//   tweet_url: string;
//   tweet_preview: string;
//   gen_replies: {
//     options: { text: string, id: number }[]
//   }
//   medias: string[]
// }

const extractMediaUrl = (text) => {
  return "";
};

const MediaPreview = ({ src }) => {
  const cleanedSrcPath = (
    src.split(
      "https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/"
    )[1] || ""
  ).replace("/", "%2F");
  const _cleanedSrc = `https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/${cleanedSrcPath}`;
  const cleanedSrc = src.includes(
    "https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/"
  )
    ? _cleanedSrc
    : src;
  console.log(`cleanedSrc`, cleanedSrc);
  if (
    cleanedSrc.includes("jpg") ||
    cleanedSrc.includes("png") ||
    cleanedSrc.includes("jpeg")
  ) {
    return (
      <img
        src={cleanedSrc}
        alt="Media"
        className="media-preview"
        style={{ maxWidth: "90vw" }}
      />
    );
  } else if (
    cleanedSrc.includes("mp4") ||
    cleanedSrc.includes("mov") ||
    cleanedSrc.includes("webm")
  ) {
    return (
      <div>
        <video controls className="media-preview" style={{ maxWidth: "90vw" }}>
          <source src={cleanedSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <br />
        <a
          href={cleanedSrc}
          download={cleanedSrc}
          target="_blank"
          rel="noopener noreferrer"
        >
          Download Video
        </a>
      </div>
    );
  }
};

function uriDecodeObject(encoded) {
  try {
    // Add back padding if needed
    encoded = encoded.replace(/-/g, "+").replace(/_/g, "/");
    const padding = encoded.length % 4;
    if (padding) {
      encoded += "=".repeat(4 - padding);
    }

    // Decode base64 to string and then parse as JSON
    const jsonString = decodeURIComponent(escape(atob(encoded)));
    return JSON.parse(jsonString);
  } catch (error) {
    console.error("Error decoding payload:", error);
    return null;
  }
}

function ReplySnipingPage() {
  const [payloadData, setPayloadData] = useState(null);
  const [mainMediaUrl, setMainMediaUrl] = useState("");

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const payload = query.get("payload");

  useEffect(() => {
    if (payload) {
      console.log("Payload: ", payload);
      // const payloadData = uriDecodeObject(payload);
      const decodedData = uriDecodeObject(payload);
      if (decodedData) {
        setPayloadData(decodedData);
      } else {
        console.log("Failed to decode payload data");
      }
      const mainMedia = extractMediaUrl(payloadData.tweet_preview);
      console.log("mainMedia", mainMedia);
      setMainMediaUrl(mainMedia);
    }
  }, []);

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.success("Text copied to clipboard!");
      })
      .catch((err) => {
        message.error("Failed to copy text.");
        console.error("Copy failed: ", err);
      });
  };

  if (!payloadData) {
    return "loading...";
  }

  console.log(`payloadData refresh`, payloadData);

  return (
    <div className="App">
      <Title level={2} className="form-title">
        Reply Sniper
      </Title>
      <Paragraph className="form-description">
        Reply to{" "}
        <a
          href={payloadData.tweet_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {payloadData.tweet_url}
        </a>{" "}
        as X profile <b>{payloadData.reply_as}</b>
      </Paragraph>
      <br />
      <h3>Preview</h3>
      <TextArea
        value={payloadData.tweet_preview}
        readOnly
        rows={4}
        className="copy-textarea"
      />
      <MediaPreview src={mainMediaUrl} />

      <a href={payloadData.tweet_url} target="_blank" rel="noopener noreferrer">
        <Button
          type="primary"
          icon={<ExportOutlined />}
          style={{ marginTop: "8px" }}
          block
        >
          Reply to Tweet
        </Button>
      </a>
      <br />
      <br />
      <br />
      <h3>Text Options</h3>
      {JSON.parse(payloadData.gen_replies).options.map((option) => (
        <div key={option.id}>
          <TextArea
            value={option.text}
            readOnly
            rows={4}
            className="copy-textarea"
          />
          <Button
            icon={<CopyOutlined />}
            onClick={() => handleCopy(option.text)}
            style={{ marginTop: "-5px", marginBottom: "20px" }}
            block
          >
            Copy Reply
          </Button>
        </div>
      ))}
      <br />
      <h3>Media Options</h3>
      {payloadData.medias.map((media) => (
        <div key={media}>
          <MediaPreview src={media} />
        </div>
      ))}
    </div>
  );
}

export default ReplySnipingPage;
